//https://github.com/auth0/auth0-spa-js

import { createContext, useEffect, useReducer } from "react";
import { Auth0Client } from "@auth0/auth0-spa-js";
import { useRouter } from "next/router";

import { auth0Config, dashboardConfig } from "../config";

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";

let auth0Client = null;

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const reducer = (state, action) => {
  if (action.type === INITIALIZE) {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  }
  if (action.type === SIGN_IN) {
    const { user } = action.payload;
    return { ...state, isAuthenticated: true, user };
  }
  if (action.type === SIGN_OUT) {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }
  return state;
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {

  const [state, dispatch] = useReducer(reducer, initialState);
  const router = useRouter();

  useEffect(() => {
    const initialize = async () => {
      try {

        console.log(dashboardConfig, auth0Config)
        auth0Client = new Auth0Client({
          client_id: auth0Config.clientId || "",
          domain: auth0Config.domain || "",
          redirect_uri: dashboardConfig.host + '/auth/callback',
        });

        window.auth0Client = auth0Client; //make available global

        if(router.route === '/auth/callback') {
          const redirectResult = await auth0Client.handleRedirectCallback();
          //logged in. you can get the user profile like this:
          const user = await auth0Client.getUser();
          console.log(user);
          // debugger
          // router.push('/dashboard/analytics')
          window.location = '/';
        } else {

          await auth0Client.checkSession();
  
          const isAuthenticated = await auth0Client.isAuthenticated();

          if (isAuthenticated) {
            const user = await auth0Client.getUser();
  
            dispatch({
              type: INITIALIZE,
              payload: { isAuthenticated, user: user || null },
            });
            
          } else {
            dispatch({
              type: INITIALIZE,
              payload: { isAuthenticated, user: null },
            });
            router.push('/auth/sign-in')
          }
        }
        
      } catch (err) {
        console.error(err);
        dispatch({
          type: INITIALIZE,
          payload: { isAuthenticated: false, user: null },
        });
      }
    }

    if(router.pathname !== "/dashboards/orders" && !router.pathname.includes("/strava")) {
      initialize();
    }

  }, []);

  const signIn = async () => {
    // await auth0Client?.loginWithPopup();
    await auth0Client?.loginWithRedirect();
    // await auth0.loginWithRedirect();
    // const isAuthenticated = await auth0Client?.isAuthenticated();

    // if (isAuthenticated) {
    //   const user = await auth0Client?.getUser();
    //   dispatch({ type: SIGN_IN, payload: { user: user || null } });
    // }
  };

  const signOut = () => {
    auth0Client?.logout();
    dispatch({ type: SIGN_OUT });
  };

  const resetPassword = (email) => {};

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "auth0",
        user: {
          id: state?.user?.sub,
          avatar: state?.user?.picture,
          email: state?.user?.email,
          displayName: state?.user?.email,
          // role: "user",
          roles: state?.user?.["https://thefeed.us.auth.com/roles"],
        },
        signIn,
        signOut,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
